<template>
    
    <Header />

    <DownButton to="founders" />

    <div id="founders"
         class="md:container 
                md:mx-auto">

      <h2 class="font-regular 
                 text-3xl 
                 md:text-3xl">
        OUR FOUNDERS<span class="text-red">.</span>
      </h2>
                  
      <div class="mb-10
                  md:grid
                  md:grid-cols-2
                  md:gap-10
                  lg:grid-cols-3
                  lg:gap-0">

        <ShortProfile v-for="founder in founders" 
                      :profile="founder"
                      :key="founder.id" />

      </div>

      <DownButton to="staff"/>

      <h2 id="staff"
          class="font-regular 
                 text-3xl 
                 md:text-3xl">
        OUR TEAM<span class="text-red">.</span>
      </h2>

      <div v-if="staff.length > 0"
           class="mb-10
                  md:grid
                  md:grid-cols-2
                  md:gap-10
                  lg:grid-cols-3
                  lg:gap-0">

        <ShortProfile v-for="profile in staff" 
                      :profile="profile"
                      :key="profile.id" />
      </div>
      <p v-else class="mt-10">Staff details coming soon.</p>

    </div>

    <UpButton to="top" />

</template>

<script>
import Header from '@/components/TeamHeader.vue'
import ShortProfile from '@/components/ShortProfile.vue'
import DownButton from '@/components/DownButton.vue'
import UpButton from '@/components/UpButton.vue'

// vue
import { computed, onMounted } from 'vue'

// vuex
import { useStore } from 'vuex'

export default {
  name: 'Team',
  components: {
    Header,
    ShortProfile,
    DownButton,
    UpButton
  },
  setup() {
    const store = useStore()

    // required for when user clicks on the back button in staffDetail, as router-link-active is not automattically added when using the router.go(-1) directly
    onMounted(() => document.getElementsByClassName("team").forEach(el => el.classList.add("router-link-active")))

    const founders = computed(() => store.getters.profilesByType('founder'))
    
    const staff = computed(() => store.getters.profilesByType('staff'))
    
    return {
      founders,
      staff
    }
  }
}
</script>