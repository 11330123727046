<template>

  <div v-if="featuredContent" class="mt-10
                                     md:flex">

    <div :style="topLeftRedDot2X2GridBackground">

        <div class="py-14 
                    pl-14 
                    pr-8">

          <router-link :to="{ name: 'ShowDetail',
                              params: {
                                  type: featuredContent.type,
                                  id: featuredContent.id
                              } }">

              <div v-if="featuredContent.logo_url"
                   class="w-52
                          h-52">

                <img class="rounded-xl
                          bg-darkgrey"
                     :src="featuredContent.logo_url" />

              </div>
              <div v-else
                   class="w-52
                          h-52
                          rounded-xl
                          bg-darkgrey"></div>

          </router-link>

      </div>
      
    </div>
      
    <div class="bg-backgroundgrey 
                  mb-16 
                  mr-16
                  md:mt-14">

      <h2 class="font-regular
                  text-2xl
                  md:text-2xl
                  mb-4">
        {{ featuredContent.name }}
      </h2>

      <p class="mb-6" v-html="featuredContent.description"></p>

      <router-link :to="{ name: 'ShowDetail',
                          params: {
                              type: featuredContent.type,
                              id: featuredContent.id
                          } }">

        <Button darkMode="true"
                paddingX="4" 
                paddingY="4" 
                buttonText="LEARN MORE"
                class="self-end" /> 

      </router-link>
      
    </div>

  </div>

</template>

<script>
import redDotGrid2X2 from '@/assets/images/misc/2x2-red-dot-grid.svg'

import Button from './Button.vue'

// vue
import { ref } from 'vue'

export default {
  name: 'Featured',
  components: {
    Button
  },
  props: [
    'featuredContent',
  ],
  setup(props) {

    const featuredContent = ref(props.featuredContent)

    return {
      featuredContent,
      topLeftRedDot2X2GridBackground: {
          backgroundImage: `url(${redDotGrid2X2})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0% 0%'
        }
    }
  }
}
</script>