<template>
  
    <div>

      <h1 class="font-semibold
                 text-4xl
                 md:text-5xl
                 xl:text-6xl">
        LISTEN<span class="text-red">.</span>
      </h1>
      <h1 class="font-semibold
                 text-4xl
                 md:text-5xl
                 xl:text-6xl
                 -mt-6
                 md:-mt-8">
        WATCH<span class="text-red ">.</span>
      </h1>
      <h1 class="font-semibold
                 text-4xl
                 md:text-5xl
                 xl:text-6xl
                 -mt-6
                 md:-mt-8">
        ENGAGE<span class="text-red">.</span>
      </h1>
      <p class="text-xl
                xl:text-2xl 
                font-regular">Content that <span class="text-red">educates</span> and <span class="text-red">inspires</span></p>
    </div>
</template>

<script>
export default {
  name: 'Misson'
}
</script>