<template>

  <ShowsListenHeader />

  <section v-if="featuredContent">

    <DownButton to="featured" />

     <h2 id="featured" 
         class="font-regular
                text-3xl
                md:text-3xl">
      Featured<span class="text-red">.</span>
    </h2>

    <div class="mb-20
                sm:grid 
                sm:grid-cols-2
                sm:gap-4">

      <Featured :featuredContent="featuredContent" />
    </div>

  </section>

  <section v-if="podcasts">

    <DownButton to="podcasts" />

     <h2 id="podcasts"
         class="font-regular
                text-3xl
                mb-10
                md:text-3xl">
      Podcasts<span class="text-red">.</span>
    </h2>

    <div v-if="podcasts.length > 0">

      <div class="grid
                  grid-cols-2
                
                  gap-8
                  md:grid-cols-3
                  lg:grid-cols-3
                  xl:grid-cols-4
                  relative">

        <div class="px-auto" 
              v-for="(podcast, index) in podcasts" 
              :key="index">

            <router-link :to="{ name: 'ShowDetail',
                      params: {
                          type: podcast.type,
                          id: podcast.id
                      } }">
              <div class="bg-darkgrey 
                            w-52
                            h-52
                            md:w-60
                            md:h-60
                            lg:w-52
                            lg:h-52
                            grid
                            place-items-center
                            p-16
                            md:p-10
                            lg:p-10
                            xl:p-12
                            2xl:p-10
                            borderTransition">
                    <img :src="podcast.logo_url" />
              </div>
            </router-link>

        </div>

      </div>

    </div>
    <div v-else>
      <p>Shows coming soon.</p>
    </div>

    <UpButton to="top" />

  </section>

</template>

<script>
import ShowsListenHeader from '@/components/ShowsListenHeader.vue'
import Featured from '@/components/Featured.vue'
import Shows from '@/components/Shows.vue'
import DownButton from '@/components/DownButton.vue'
import UpButton from '@/components/UpButton.vue'

// vue
import { computed, onMounted } from 'vue'

// vuex
import { useStore } from 'vuex'

export default {
  name: 'Shows',
  components: {
    ShowsListenHeader,
    Featured,
    Shows,
    DownButton,
    UpButton
  },
   setup() {

     onMounted(() => document.getElementsByClassName("shows").forEach(el => el.classList.add("router-link-active")))

     const store = useStore()
    
     const featuredContent = computed(() => store.getters.featuredContent('podcasts'))

     const podcasts = computed(() => store.getters.podcasts(true))

     return {
       featuredContent,
       podcasts
     }
   }
}
</script>