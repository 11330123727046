<template>

  <header class="flex 
                 justify-between">

    <div class="w-full
                md:w-1/2
                mt-12">

      <h1 class="font-semibold
                 text-4xl
                 xl:text-5xl">
        VLOGS<span class="text-red">.</span>
      </h1>
      
      <p class="text-xl
                xl:text-lg 
                font-regular
                mb-10">
                We provide a full service vlog production service that enables content creators to engage their followers and show them a glimpse of their lifestyle.
      </p>

      <p class="text-xl
                xl:text-lg 
                font-regular
                mb-10">Our vlog packages enable you to focus on producing engaging, educational and inspiring content, whilst we take care of the rest.
      </p>

    </div>
    
     <div :style="topRightRedDot5X5GridBackground" 
          class="hidden 
                 md:block 
                 ml-10
                 w-1/2">
      <div :style="bottomLeftRed2X2DotGridBackground">
        <div class="p-14">
          <img :src="vlogsImg" 
                class="p-0 
                       md:p-0 
                       rounded-tl-2xl
                       rounded-br-2xl" />
        </div>
      </div>
     </div>

  </header>

</template>

<script>
import redDotGrid5X5 from '@/assets/images/misc/5x5-red-dot-grid.svg'
import redDotGrid2X2 from '@/assets/images/misc/2x2-red-dot-grid.svg'
import vlogsImg from '@/assets/images/misc/watch.jpg'

import Mission from './Mission.vue'
import SocialMediaLinks from './SocialMediaLinks.vue'
import Button from './Button.vue'

export default {
  name: "ServicesVlogsHeader",
  components: {
    Mission,
    SocialMediaLinks,
    Button
  },
  setup() {

     return {
        vlogsImg,
        topRightRedDot5X5GridBackground: {
          backgroundImage: `url(${redDotGrid5X5})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '100% 0%'
        },
        bottomLeftRed2X2DotGridBackground: {
          backgroundImage: `url(${redDotGrid2X2})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0% 100%'
        },
      }
  }
};
</script>