<template>

  <BackButton />

  <div v-if="profile">

    <div class="mb-10
                pt-12
                md:container 
                md:mx-auto
                md:grid
                md:grid-cols-3
                md:gap-4"> 

      <img :src="profile.image_url" 
            class="rounded-xl
                   w-52
                   h-52" />

      <div class="md:col-span-2
                  lg:col-span-2">

        <h3 class="mt-4
                  md:mt-0
                  font-bold
                  text-xl">{{ profile.fullName }}<span class="text-red">.</span></h3>

        <p class="mb-4">{{ profile.role }}</p>

        <SocialMediaLinks :displayingInFooter="false"
                          :darkIcons="true"
                          :socialMediaURLS="profile.socialMediaURLS"/>

        <p v-html="profile.bio" class="mt-4"></p>

      </div>
                
    </div>

  </div>
  <div v-else>
    <NotFound />
  </div>

</template>

<script>
import SocialMediaLinks from '@/components/SocialMediaLinks.vue'
import NotFound from '@/components/NotFound.vue'
import BackButton from '@/components/BackButton.vue'

// vue
import { computed, onMounted, onUnmounted } from 'vue'

// vuex
import { useStore } from 'vuex'

// vue-router
import { useRouter } from 'vue-router'

export default {
  name: 'ProfileDetail',
  props: [
    'id'
  ],
  components: {
      SocialMediaLinks,
      NotFound,
      BackButton
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()

    // computed 
    const profile = computed(() => store.getters.profile(props.id)[0])
    
    const back = () => router.go(-1)

     // add / remove active link to staff link in navigation
    onMounted(() => document.getElementsByClassName("team").forEach(el => el.classList.add("router-link-active")))
    onUnmounted(() => document.getElementsByClassName("team").forEach(el => el.classList.remove("router-link-active")))

    return {
      profile,
      back
    }
  },
}
</script>
