<template>

  <div v-if="profile"
       class="mt-10
              mb-10
              grid">

         <img :src="image_url" 
               class="rounded-xl
                      w-52
                      h-52" />
  
        <h3 class="mt-4
                   font-bold
                   text-xl">{{ fullName }}<span class="text-red">.</span></h3>

        <p class="mb-8">{{ role }}</p>

        <router-link class="font-semibold
                            self-end"
                            :to="{ 
                              name: 'ProfileDetail', 
                              params: {
                                id
                              } 
                            }">

          <Button buttonText="VIEW PROFILE"/> 

        </router-link>  

    </div>

</template>

<script>
import Button from '@/components/Button.vue'

export default {
  name: 'ShortProfile',
  props: [
    'profile'
  ],
  components: {
      Button
  },
  setup(props) {

      const {
          id,
          fullName,
          image_url,
          role
          } = props.profile

      return {
          id,
          fullName,
          image_url,
          role
      }
  }
}
</script>