<template>
  
  <BackButton />

  <div v-if="show" class="mt-10">

    <PodcastDetail v-if="show.type === 'podcast'" :show="show" />
    <VlogDetail v-if="show.type === 'vlog'" :show="show" />
    
  </div>

</template>

<script>
import leftArrow from '@/assets/images/icons/leftArrow.svg'

import Button from '@/components/Button.vue'
import BackButton from '@/components/BackButton.vue'
import PodcastDetail from './PodcastDetail.vue'
import VlogDetail from './VlogDetail.vue'
import NotFound from '@/components/NotFound.vue'

// vue
import { computed, onMounted, onUnmounted } from 'vue'

// vuex
import { useStore } from 'vuex'

// vue-router
import { useRouter } from 'vue-router'

export default {
  name: 'ShowDetail',
  props: [
    'type',
    'id'
  ],
  components: {
    Button,
    BackButton,
    PodcastDetail,
    VlogDetail,
    NotFound
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()

    // computed 
    const show = computed(() => store.getters.content(props.type, props.id))

    const back = () => router.go(-1)

    // add / remove active link to staff link in navigation
    onMounted(() => document.getElementsByClassName("shows").forEach(el => el.classList.add("router-link-active")))
    onUnmounted(() => document.getElementsByClassName("shows").forEach(el => el.classList.remove("router-link-active")))

    return {
      show,
      back,
      leftArrow
    }
  }
}
</script>