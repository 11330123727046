<template>

    <div v-if="host"
         class="mt-10
                bg-darkgrey
                rounded-xl
                px-10
                pt-2
                md:mx-2">
  
        <h3 class="mt-4
                   font-bold
                   text-xl">{{ fullName }}<span class="text-red">.</span></h3>
       
        <p class="mt-4 
                  pb-10
                  md:mb-0
                  text-base"
            v-html="bio"></p>
    </div>
    
</template>

<script>
export default {
    name: 'Host',
    props: [
        'host'
    ],
    setup(props) {
        const fullName = props.host.fullName
        const bio = props.host.bio

        return {
            fullName,
            bio
        }
    }
}
</script>