<template>

  <header class="flex 
                 justify-between">

    <Mission />
    
     <div :style="topRightRedDot5X5GridBackground" 
          class="hidden 
                 md:block 
                 ml-10">
      <div :style="bottomLeftRed2X2DotGridBackground">
        <div class="p-14">
          <img :src="podcastingImg" 
                class="p-0 
                      md:p-0 
                      rounded-tl-2xl
                      rounded-br-2xl" />
        </div>
      </div>
     </div>

  </header>

</template>

<script>
import redDotGrid5X5 from '@/assets/images/misc/5x5-red-dot-grid.svg'
import redDotGrid2X2 from '@/assets/images/misc/2x2-red-dot-grid.svg'
import podcastingImg from '@/assets/images/misc/podcasting.jpg'

import Mission from './Mission.vue'
import SocialMediaLinks from './SocialMediaLinks.vue'

export default {
  name: "HomepageHeader",
  components: {
    Mission,
    SocialMediaLinks
  },
  setup() {

     return {
        podcastingImg,
        topRightRedDot5X5GridBackground: {
          backgroundImage: `url(${redDotGrid5X5})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '100% 0%'
        },
        bottomLeftRed2X2DotGridBackground: {
          backgroundImage: `url(${redDotGrid2X2})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0% 100%'
        },
      }
  }
};
</script>