import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBWtQXhd9nYVrdsW4H7NZTnE45yDLQGoGE",
  authDomain: "twenty-one-media.firebaseapp.com",
  projectId: "twenty-one-media",
  storageBucket: "twenty-one-media.appspot.com",
  messagingSenderId: "839937798748",
  appId: "1:839937798748:web:b0d87143006f11e44b2c99"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app);
const timestamp = Timestamp

// init firebase auth
const auth = getAuth()
setPersistence(auth, browserSessionPersistence)

export { 
    db,
    storage,
    timestamp,
    auth
}

export default app
