<template> 
  <button @click="buttonClicked" class="mobile-menu-button" buttonText="">
    <svg xmlns="http://www.w3.org/2000/svg" 
          class="h-6 
                 w-6 
                hover:text-red 
                 transition 
                 duration-300 
                 ease-in-out" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor">
            <path stroke-linecap="round" 
                  stroke-linejoin="round" 
                  stroke-width="2" 
                  d="M4 6h16M4 12h16M4 18h16" />
    </svg>
  </button>       
</template>

<script>
  export default {
    name: 'MobileNavigationButton',
    setup(props, context) {
      const buttonClicked = () => {
        context.emit('toggleMobileNavigationMenu')
      }
    
      return {
        buttonClicked
      }
    }
  }
</script>