<template>

    <div class="flex
                w-9/12
                sm:w-4/12
                md:w-4/12
                lg:w-1/4
                mx-auto
                mb-10
                font-semibold">
        <p class="text-red
                px-4
                ml-4">Podcasts</p>
        |
        <router-link :to="{ name: 'ServicesVlogs' }">
        <p class="navigationLink
                    px-4">Vlogs</p> 
        </router-link>
    </div>

    <ServicesPodcastsHeader />

    <DownButton to="package-details" />

    <section id="package-details" class="flex justify-center" >

        <h2 class="mb-16 
                   text-2xl
                   md:text-3xl">
             Our podcast package includes<span class="text-red">.</span>
        </h2>

    </section>

    <Package :packageDetails="serviceContent.packageDetails" />

    <DownButton to="distributers" />

    <div class="flex 
                justify-center">
      <div id="distributers" 
          class="
                  grid
                  grid-cols-5
                  gap-4
                  relative
                  justify-center">

        <img :src="spotifyLogo" 
              class="w-48 
                    md:w-32
                    h-auto
                    max-h-52
                    rounded-xl
                    bg-darkgrey" />

        <img :src="googlePodcastsLogo" 
              class="w-48 
                    md:w-32
                    h-auto
                    max-h-52
                    rounded-xl
                    bg-darkgrey" />

        <img :src="pocketCastsLogo" 
              class="w-48 
                    md:w-32
                    h-auto
                    max-h-52
                    rounded-xl
                    bg-darkgrey" />
                  
        <img :src="stitcherLogo" 
              class="w-48
                    md:w-32
                    h-auto
                    max-h-52
                    rounded-xl
                    bg-darkgrey" />
                  
        <img :src="overcastLogo" 
              class="w-48 
                    md:w-32
                    h-auto
                    max-h-52
                    rounded-xl
                    bg-darkgrey" />

      </div>
    </div>

    <DownButton to="get-in-touch" />

    <div id="get-in-touch"
         class="w-44
               pl-2
               mt-20
               mx-auto
               justify-content">

      <router-link :to="{ name: 'GetInTouch' }">
        <Button darkMode="true"
                paddingX="4" 
                paddingY="4" 
                buttonText="GET IN TOUCH"
                class="self-end" /> 
      </router-link>
    </div>

    <UpButton to="top" />

</template>

<script>
import spotifyLogo from '@/assets/images/logo/distributionLogos/spotifyLogo.svg'
import googlePodcastsLogo from '@/assets/images/logo/distributionLogos/googlePodcastsLogo.svg'
import pocketCastsLogo from '@/assets/images/logo/distributionLogos/pocketCastsLogo.svg'
import stitcherLogo from '@/assets/images/logo/distributionLogos/stitcherLogo.svg'
import overcastLogo from '@/assets/images/logo/distributionLogos/overcastLogo.svg'

import ServicesPodcastsHeader from '@/components/ServicesPodcastsHeader.vue'
import Package from '@/components/Package.vue'
import Button from '@/components/Button.vue'
import DownButton from '@/components/DownButton.vue'
import UpButton from '@/components/UpButton.vue'


// vue
import { computed, onMounted, onUnmounted  } from 'vue'

// vuex
import { useStore } from 'vuex'

export default {
  name: 'ServicesPodcasts',
  components: {
    ServicesPodcastsHeader,
    Package,
    Button,
    DownButton,
    UpButton
  },
   setup() {
     
    // add / remove link styling when mounting and unmouting the view
    onMounted(() => document.getElementsByClassName("services").forEach(el => el.classList.add("router-link-active")))
    onUnmounted(() => document.getElementsByClassName("services").forEach(el => el.classList.remove("router-link-active")))

     const store = useStore()
    
     const serviceContent  = computed(() => store.getters.servicesContent('podcasts'))

     return {
       spotifyLogo,
       googlePodcastsLogo,
       pocketCastsLogo,
       stitcherLogo,
       overcastLogo,
       serviceContent
     }
   }
}
</script>