<template>

 <div v-if="show" class="mt-14
                         md:flex">

      <img v-if="show.logo_url"
           :src="show.logo_url"
           class="w-52
                  h-52
                  rounded-xl
                  bg-darkgrey" />
      <div v-else
           class="w-52
                  h-52
                  rounded-xl
                  bg-darkgrey"></div>

      <div class="md:w-3/4
                  lg:w-1/2
                 bg-backgroundgrey 
                  mt-6
                  md:mt-0
                  mb-16 
                  md:mx-10">

          <h2 class="font-regular
                     text-2xl
                     md:text-2xl
                     mb-4">
            {{ show.name }}<span class="text-red">.</span>
          </h2>

        
          <div class="mb-6" 
              v-html="show.description"></div>

          <a v-if="show.episodesURL"
            :href='show.episodesURL' 
            target="_blank">
            <Button buttonText="LISTEN TO ALL EPISODES"
                    class="self-end" /> 
          </a>
          
      </div>

  </div>

  <section v-if="show.hosts.length > 0">

    <DownButton to="hosts" />

    <h2 id="hosts" 
        class="font-regular
               mb-10
               text-3xl
               md:text-3xl
               w-48
               mx-auto">
      The Host<span v-if="show.hosts.length > 1">s</span><span class="text-red">.</span>
    </h2>

      <section class="mb-20
                      md:grid
                      md:gap-0"
               :class="[show.hosts.length > 1 ? 'md:grid-cols-2' : 'md:grid-cols-1 md:w-3/4 lg:w-7/12 mx-auto']">


        <Host v-for="host in show.hosts" 
              :host="host" 
              :key="host.id"/>

    </section>

  </section>

  <UpButton to="top" />

</template>

<script>
import Host from '@/components/Host.vue'
import Button from '@/components/Button.vue'
import DownButton from '@/components/DownButton.vue'
import UpButton from '@/components/UpButton.vue'

export default {
  name: 'PodcastDetail',
  props: [
    'show'
  ],
  components: {
    Host,
    Button,
    DownButton,
    UpButton
  },
  setup (props) {
    const show = props.show

    return {
      show
    }
  }
}
</script>