<template>

   <h2 class="font-regular
                text-3xl
                mb-10
                md:text-3xl">
      Vlogs<span class="text-red">.</span>
   </h2>
    
  <router-link :to="{ name: 'AdminCreateVlog' }">

    <button class="transition 
                   duration-300 
                   ease-in-out 
                   text-white
                   text-center 
                   py-2 
                   px-4 
                   bg-darkgrey 
                   hover:bg-red 
                   hover:text-white
                   font-semibold 
                   text-sm 
                   tracking-wide 
                   rounded-full
                   mb-10">
      Add a new vlog
    </button>

  </router-link>

  <div v-if="vlogs.length > 0">

      <div class="grid
                  grid-cols-2
                  gap-2
                  md:grid-cols-3
                  md:gap-4
                  md:pr-20
                  lg:grid-cols-4
                  xl:grid-cols-4
                  relative">

        <div class="px-auto" 
              v-for="(vlog, index) in vlogs" 
              :key="index">

          <router-link :to="{ name: 'AdminEditVlog',
                params: {
                    type: vlog.type,
                    id: vlog.id
                } }">

            <img v-if="vlog.logo_url"
                :src="vlog.logo_url"
                class="w-52
                      h-auto
                      max-h-48
                      rounded-xl
                      bg-darkgrey
                      border-4
                      md:border-8
                      border-white
                      shadow-xl 
                      shadow-darkgrey" />
            <div v-else
                 class="w-52
                        h-auto
                        rounded-xl
                        bg-darkgrey"></div>

          </router-link>
          
        </div>

      </div>

  </div>
  <div v-else>
    <p>Shows coming soon.</p>
  </div>

</template>

<script>
import Shows from '@/components/Shows.vue'

// vue
import { computed, onMounted } from 'vue'

// vuex
import { useStore } from 'vuex'

export default {
  name: 'AdminShowsVlogs',
  components: {
    Shows
  },
  setup() {
    
    onMounted(() => {
      document.getElementsByClassName("adminVlogs").forEach(el => el.classList.add("router-link-active"))
      document.getElementsByClassName("adminPodcasts").forEach(el => el.classList.remove("router-link-active"))
    })

    const store = useStore()

    let vlogs = computed(() => store.getters.vlogs(true))

    return {
      vlogs
    }
  }
}
</script>
