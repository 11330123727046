<template>

  <div v-if="isAdminArea" class="h-screen 
                                 bg-verylightgrey">
    <div class="bg-verylightgrey
                  bg-contain
                  min-h-screen">
      <router-view />
    </div>
  </div>
  <div v-else 
       class="px-10
              py-4
              min-h-full
              text-white 
              pb-32
              md:container
              sm:px-10
              md:mx-auto
              lg:px-48 
              2xl:px-80">

      <Navigation v-if="displayNavigationAndFooter" />
      <router-view />
      <Footer v-if="displayNavigationAndFooter" />
  </div>

</template>

<script>
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Navigation,
    Footer
  },
  data() {
    return {
      displayNavigationAndFooter: true,
      isAdminArea: false,
      isSignedIn: false
    }
  },
  created() {

    // Podcasts
    this.$store.dispatch('getPodcastsFromDb')

    // Vlogs
    this.$store.dispatch('getVlogsFromDb')

    // Blogs - UNCOMMENT TO ADD BLOG FUNCTIONALITY 
    /*
      this.$store.dispatch('getVBlogsFromDb')
    */
  },
  methods: {
    checkRoute() {
      if(this.$route.name === 'Login' ||
         this.$route.name === 'Register' ||
         this.$route.name === 'ForgotPassword' ||
         this.$route.name === 'Admin' ||
         this.$route.name === 'AdminShows' ||
         this.$route.name === 'AdminShowsPodcasts' ||
         this.$route.name === 'AdminShowsVlogs' ||
         this.$route.name === 'AdminCreatePodcast' ||
         this.$route.name === 'AdminCreateVlog' ||
         this.$route.name === 'AdminCreateBlog' ||
         this.$route.name === 'AdminEditPodcast' ||
         this.$route.name === 'AdminEditVlog' ||
         this.$route.name === 'AdminEditBlog' ||
         this.$route.name === 'AdminEditShow') {
           this.displayNavigationAndFooter = false
           this.isAdminArea = true
           return
         } else {
            this.displayNavigationAndFooter = true
            this.isAdminArea = false
        }
    }
  },
  watch: {
    $route() {
      this.checkRoute()
    }
  }
}
</script>
