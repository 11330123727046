import { storage } from '../config/firebase'
import { ref, 
         uploadBytesResumable, 
         getDownloadURL } from "firebase/storage";

const uploadFileToFirebaseStorage = (file, pathInFirebase) => {
    console.log('uploadFileToFirebaseStorage > file', file)
    console.log('uploadFileToFirebaseStorage > pathInFirebase', pathInFirebase)

    return new Promise((resolve, reject) => {
                      
    const metadata = {
      contentType: file.type
    };

    // Upload file and metadata to the object
    const storageRef = ref(storage, pathInFirebase + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
      }, 
      (error) => reject(error), 
      () => {
        // Upload to Firebase Storage completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref)
          .then(downloadURL => {
            // console.log('File available at', downloadURL);
            // URL of the image in Firebase Storage - used in the <img src"" />
            resolve(downloadURL)
          });
      }
  );

  });
}

export default uploadFileToFirebaseStorage