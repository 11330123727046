<template>

  <Header />

</template>

<script>
import Header from '@/components/GetInTouchHeader.vue'

export default {
  name: 'GetInTouch',
   components: {
    Header
   }
}
</script>