<template>

  <header class="flex 
                 justify-between">

    <div class="w-full
                md:w-1/2
                mt-16">

      <h1 class="font-semibold
                 text-5xl
                 xl:text-6xl">
        LISTEN<span class="text-red">.</span>
      </h1>
      
      <p class="text-xl
                xl:text-2xl 
                font-regular
                mb-10">Inspirational and educational podcasts that inform, engage, entertain and ask the important questions.</p>

      <a href="#podcasts">
        <Button darkMode="true"
                paddingX="4" 
                paddingY="4" 
                buttonText="VIEW OUR PODCASTS"
                class="self-end" /> 
      </a>

    </div>
    
     <div :style="topRightRedDot5X5GridBackground" 
          class="hidden 
                 md:block 
                 ml-10">
      <div :style="bottomLeftRed2X2DotGridBackground">
        <div class="p-14">
          <img :src="listenImg" 
                class="p-0 
                      md:p-0 
                      rounded-tl-2xl
                      rounded-br-2xl" />
        </div>
      </div>
     </div>

  </header>

</template>

<script>
import redDotGrid5X5 from '@/assets/images/misc/5x5-red-dot-grid.svg'
import redDotGrid2X2 from '@/assets/images/misc/2x2-red-dot-grid.svg'
import listenImg from '@/assets/images/misc/listen.jpg'

import Mission from './Mission.vue'
import SocialMediaLinks from './SocialMediaLinks.vue'
import Button from './Button.vue'

export default {
  name: "ShowsListenHeader",
  components: {
    Mission,
    SocialMediaLinks,
    Button
  },
  setup() {

     return {
        listenImg,
        topRightRedDot5X5GridBackground: {
          backgroundImage: `url(${redDotGrid5X5})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '100% 0%'
        },
        bottomLeftRed2X2DotGridBackground: {
          backgroundImage: `url(${redDotGrid2X2})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0% 100%'
        },
      }
  }
};
</script>