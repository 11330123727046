<template>

    <nav class="max-w-full
                font-semibold
                text-lg
                tracking-wide
                mb-10
                sticky 
                top-0
                pt-4 
                z-50
                bg-backgroundgrey
                bg-opacity-90">

            <!-- Nav Links -->
            <div class="hidden 
                        lg:flex
                        width: auto 
                        justify-between
                        items-center
                        mx-auto">

                <router-link class="shows navigationLink text-base" 
                             :to="{ name: 'Shows' }">SHOWS</router-link>

                <router-link class="services navigationLink text-base ml-6" 
                             :to="{ name: 'Services' }">SERVICES</router-link>

                <router-link class="header" 
                             :to="{ name: 'Home' }">
                    <img :src="logo" 
                          alt="down arrow"
                          class="w-10 
                                 mb-6
                                 mx-auto" />
                </router-link>

                <router-link class="team 
                                    navigationLink 
                                    text-base
                                    -mr-10" 
                             :to="{ name: 'Team' }">TEAM</router-link>

                <router-link :to="{ name: 'GetInTouch' }">
                    <Button class="my-6" 
                            buttonText="GET IN TOUCH" />
                </router-link>
            </div>


            <!-- Mobile navigation button-->
            <div class="lg:hidden 
                        w-full
                        flex
                        justify-between
                        items-center">

                <router-link class="header" 
                             :to="{ name: 'Home' }"
                             @click="toggleMobileNavigationMenu">
                    <img class="h-14
                                mb-4
                                w-auto 
                                cursor-pointer" 
                         :src="logo" 
                          alt="Logo" />
                </router-link>

                <MobileNavigationButton @toggleMobileNavigationMenu="toggleMobileNavigationMenu" />
            </div>

            <!-- Mobile navigation menu-->
            <div :class="{ hidden: hideMobileNavigationMenu }" 
                class="lg:hidden 
                        py-6">
                <router-link class="shows mobileNavigationLink" :to="{ name: 'Shows' }" @click="toggleMobileNavigationMenu">Shows</router-link>
                <router-link class="services mobileNavigationLink" :to="{ name: 'Services' }" @click="toggleMobileNavigationMenu">Services</router-link>
                <router-link class="team mobileNavigationLink" :to="{ name: 'Team' }" @click="toggleMobileNavigationMenu">Team</router-link>
                <router-link :to="{ name: 'GetInTouch' }" @click="toggleMobileNavigationMenu">
                    <Button class="my-6" 
                            buttonText="GET IN TOUCH" />
                </router-link>
            </div>

    </nav>

</template>

<script>
// vue
import { ref } from 'vue'

// @ refers to the project root directory
import Button from './Button.vue'
import MobileNavigationButton from './MobileNavigationButton.vue'

import logo from '@/assets/images/logo/21MediaLogoWhite.svg'
import mobileMenuIcon from '@/assets/images/icons/mobileMenuIcon.svg'

export default {
    name: 'Navigation',
    components: {
        Button,
        MobileNavigationButton
    },
    setup() {
        let hideMobileNavigationMenu = ref(true)

        const toggleMobileNavigationMenu = () => hideMobileNavigationMenu.value = !hideMobileNavigationMenu.value

        return {
            logo,
            mobileMenuIcon,
            hideMobileNavigationMenu,
            toggleMobileNavigationMenu
        }
    }
}
</script>