<template>

  <header class="flex 
                 justify-between">

    <div>

      <span class="md:flex">
        <h1 class="font-semibold
                  text-5xl
                  xl:text-6xl">
          MEET
        </h1>
        <h1 class="font-semibold
                   -mt-12
                   sm:ml-0
                   md:mt-0
                   md:ml-6
                   text-5xl
                   xl:text-6xl">
          THE
        </h1>
      </span>
      <h1 class="font-semibold
                 text-5xl
                 xl:text-6xl
                 -mt-12
                 md:-mt-8">
        TEAM<span class="text-red">.</span> 
      </h1>

    </div>

  </header>

</template>

<script>
import redDotGrid5X5 from '@/assets/images/misc/5x5-red-dot-grid.svg'
import redDotGrid2X2 from '@/assets/images/misc/2x2-red-dot-grid.svg'
import podcastingImg from '@/assets/images/misc/podcasting.jpg'

export default {
  name: "TeamHeader",
  setup() {

     return {
        podcastingImg,
        topRightRedDot5X5GridBackground: {
          backgroundImage: `url(${redDotGrid5X5})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '100% 0%'
        },
        bottomLeftRed2X2DotGridBackground: {
          backgroundImage: `url(${redDotGrid2X2})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0% 100%'
        },
      }
  }
};
</script>