<template>

  <div v-if="packageDetails" class="font-semibold rounded-xl">

      <div class="packageDetails" 
          v-html="packageDetails"></div>
  </div>

</template>

<script>
export default {
  name: 'Package',
  props: [
    'packageDetails',
  ],
  setup(props) {
    
    const packageDetails = props.packageDetails

    return {
      packageDetails
    }
  }
}
</script>

<style lang="postcss">
.packageDetails ul {
  @apply 
    gap-x-4 
    gap-y-4
    grid 
    auto-rows-fr
    md:grid-flow-row 
    md:grid-cols-2 
    xl:grid-cols-3
}

.packageDetails ul li::marker {
  @apply 
    text-red
    justify-self-center
}

.packageDetails ul li {
  @apply 
    px-10
    py-6
    bg-darkgrey
    rounded-lg
}
</style>