<template>
    <a :href="'#' + to">
        <img :src="upArrow" 
            alt="down arrow"
            class="w-8 
                    my-20 
                    mx-auto" />
    </a>
</template>

<script>
import upArrow from '@/assets/images/misc/up-arrow-red-dot.svg'

export default {
    name: 'UpButton',
    props: ['to'],
    setup(props) {
        const to = props.to

        return {
            upArrow,
            to
        }
    }
}
</script>