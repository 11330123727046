<template>

  <Header />

  <DownButton to="services" />

  <section id="services" 
           class="md:grid 
                  md:grid-cols-2
                  md:gap-14">
                <!-- 
                  - use when adding a 3rd service type
                  - replace sm:grid, sm:grid-col, sm:gap above, with:
                  md:grid 
                  md:grid-cols-3
                  md:gap-10 
                -->
              
      <Service name="Podcasts" 
               description="<p>21 Media is a full service podcast management production that helps Businesses, Entrepreneurs and athletes use their podcast to market and position themselves.</p><br><p>We are passionate about amplifying professional voices by editing, managing and producing your podcast visually and through audio. We offer podcast editing and management services that are customized to suit your needs.</p><br><p>We provide you with time and freedom while you use your voice to showcase your expertise.</p>" />

      <Service name="Vlogs" 
               description="<p>21 Media provides a full service vlog production that enables content creators to engage their followers and show them a glimpse of their lifestyle.</p><br><p>We are passionate to provide services that allow clients to share visualized blogs which also provide an alternative platform to get their message out.</p><br><p>Our Vlog packages enable you to focus on producing engaging, educational and inspiring content, whilst we take care of the rest.</p>" />
  
  </section>

  <section v-if="featuredContent" 
           id="featured">

    <DownButton to="featured" />

     <h2 class="font-regular
                text-3xl
                md:text-3xl">
      Featured Show<span class="text-red">.</span>
    </h2>

    <div class="mb-20
                sm:grid 
                sm:grid-cols-1
                sm:gap-4">

      <Featured :featuredContent="featuredContent" />
    </div>

  </section>

  <UpButton to="top" />
  
</template>

<script>
import Header from '@/components/HomepageHeader.vue'
import DownButton from '@/components/DownButton.vue'
import UpButton from '@/components/UpButton.vue'
import Button from '@/components/Button.vue'
import Service from '@/components/Service.vue'
import Featured from '@/components/Featured.vue'

// vue
import { computed } from 'vue'

// vuex
import { useStore } from 'vuex'

export default {
  name: 'Home',
  components: {
    Header,
    DownButton,
    UpButton,
    Button,
    Service,
    Featured
  },
  setup() {
    const store = useStore()
    
    const featuredContent = computed(() => store.getters.featuredContent('all'))

    return {
      featuredContent
    }
  },
}
</script>
