<template>

   <div class="flex
               w-9/12
               sm:w-4/12
               md:w-4/12
               lg:w-1/4
               mx-auto
               mb-10
               font-semibold">
    <router-link :to="{ name: 'ServicesPodcasts' }">
      <p class="navigationLink
                px-4
                ml-4">Podcasts</p> 
    </router-link>
    |
    <p class="text-red
               px-4">Vlogs</p>
  </div>

  <ServicesVlogsHeader />

  <DownButton to="package-details" />

  <section id="package-details" 
           class="flex 
                  justify-center" >

        <h2 class="mb-16
                   text-2xl
                   md:text-3xl">
          Our vlog package includes<span class="text-red">.</span>
        </h2>

  </section>

  <Package :packageDetails="serviceContent.packageDetails" />

  <DownButton to="distributers" />

  <div id="distributers">

      <img :src="youtubeLogo" 
            class="w-48 
                   mx-auto
                   md:w-32
                   h-auto
                   max-h-52
                   rounded-xl
                   bg-darkgrey" />
              
  </div>

  <DownButton to="get-in-touch" />

    <div id="get-in-touch"
         class="w-44
               pl-2
               mt-20
               mx-auto
               justify-content">

      <router-link :to="{ name: 'GetInTouch' }">
        <Button darkMode="true"
                paddingX="4" 
                paddingY="4" 
                buttonText="GET IN TOUCH"
                class="self-end" /> 
      </router-link>
    </div>


  <UpButton to="top" />

  
</template>

<script>
import youtubeLogo from '@/assets/images/logo/distributionLogos/youtubeLogo.svg'

import ServicesVlogsHeader from '@/components/ServicesVlogsHeader.vue'
import Package from '@/components/Package.vue'
import Button from '@/components/Button.vue'
import DownButton from '@/components/DownButton.vue'
import UpButton from '@/components/UpButton.vue'

// vue
import { computed, onMounted, onUnmounted  } from 'vue'

// vuex
import { useStore } from 'vuex'

export default {
  name: 'ServicesVlogs',
  components: {
    ServicesVlogsHeader,
    Package,
    Button,
    DownButton,
    UpButton
  },
   setup() {
     
    // add / remove link styling when mounting and unmouting the view
    onMounted(() => document.getElementsByClassName("services").forEach(el => el.classList.add("router-link-active")))
    onUnmounted(() => document.getElementsByClassName("services").forEach(el => el.classList.remove("router-link-active")))

    const store = useStore()
    
    const serviceContent  = computed(() => store.getters.servicesContent('vlogs'))

    return {
      youtubeLogo,
      serviceContent
    }
   }
}
</script>