<template>

  <footer>

    <Navigation class="mt-20" />

    <SocialMediaLinks :displayingInFooter="true"
                      :socialMediaURLS="{ 
                                          linkedIn: 'https://www.linkedin.com/company/21-mediallc/',
                                          instagram: 'https://www.instagram.com/_21media/',
                                          facebook: 'https://www.facebook.com/21-Media-106682111136365'
                                        }"
                      class="px-10
                             md:px-28" />
  </footer>

</template>

<script>
import Navigation from './Navigation.vue'
import SocialMediaLinks from './SocialMediaLinks.vue'

export default {
  name: "Footer",
  components: {
    Navigation,
    SocialMediaLinks
  },
  setup() {
  }
};
</script>