<template>
  <div class="form-wrap">

    <form class="login" @submit.prevent="handleSubmit">

      <img class="h-10 w-auto" 
           :src="logo" alt="Logo" />

      <div class="inputs mt-10 mb-5">

        <div class="input
                    text-darkgrey">
          <input type="text" 
                 placeholder="Email" 
                 v-model="email"
                 name="email" />
        </div>

        <div class="input 
                   text-darkgrey">
          <input type="password" 
                 placeholder="Password" 
                 v-model="password" 
                 name="password" />
        </div>

        <div v-show="error" 
             class="error">
          {{ error }}
        </div>
      </div>

      <Button buttonText="LOGIN" />

    </form>
  </div>

</template>
  
<script>
import logo from '@/assets/images/logo/21-logo-icon.svg'
import Button from '../../../components/Button.vue'

import { useStore } from 'vuex'

// vue-router
import { useRouter } from 'vue-router'

import { ref } from 'vue'

export default {
  name: "Login",
  components: {
    Button
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const email = ref('')
    const password = ref('')
    const error = ref(null)

    const handleSubmit = async () => {
      try {
        await store.dispatch('login', {
          email: email.value,
          password: password.value
        })
        router.push({ name: 'AdminShows' })
      } catch (err) {
        error.value = err.message
      }
    }

    return {
      logo,
      handleSubmit,
      email, 
      password, 
      error 
    }
  }
};
</script>

<style>
.form-wrap {
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
}

@media (min-width: 900px) {
    .form-wrap {
        width: 100%;
    }
}

.form-wrap .login-register {
    margin-bottom: 32px;
}

.form-wrap .login-register .router-link {
    color: #000;
}


.form-wrap form {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

@media (min-width: 900px) {
    .form-wrap form {
        padding: 0 50px;
    }
}

.form-wrap form h2 {
    text-align: center;
    font-size: 32px;
    color: #303030;
    margin-bottom: 40px;
}
@media (min-width: 900px) {
    .form-wrap form h2 {
        font-size: 40px;
    }
}

.form-wrap form .inputs {
      width: 100%;
      max-width: 350px;
}

.form-wrap form .inputs .input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.form-wrap form .inputs .input input {
    width: 100%;
    border: none;
    background-color: #f2f7f6;
    padding: 4px 4px 4px 30px;
    height: 50px;
}

.form-wrap form .inputs .input input:focus {
    outline: none;
}
    
.form-wrap form .inputs .input .icon {
    width: 12px;
    position: absolute;
    left: 6px;
}

.angle {
    display: none;
    position: absolute;
    background-color: #fff;
    transform: rotateZ(3deg);
    width: 60px;
    right: -30px;
    height: 101%;
}

@media (min-width: 900px) {
    .angle {
        display: initial;
    }
}


@media (min-width: 900px) {
    .background {
        display: initial;
    }
}
</style>
