<template>

  <header class="flex 
                 justify-between">

    <div>

      <h1 class="font-semibold
                 text-5xl
                 xl:text-6xl
                 leading-none">
        LET'S TALK<span class="text-red">.</span> 
      </h1>

      <p class="text-xl
                xl:text-xl 
                font-regular
                my-4
                md:w-7/12">
        If you would like to get started or just have questions about how we can help you to produce world class content. Drop us an email to <a href="mailto:drew@drewlasker.com" class="navigationLink text-red">drew@drewlasker.com</a> and we will get back to you as soon as possible.
      </p>
    </div>

  </header>

</template>

<script>
import redDotGrid5X5 from '@/assets/images/misc/5x5-red-dot-grid.svg'
import redDotGrid2X2 from '@/assets/images/misc/2x2-red-dot-grid.svg'
import podcastingImg from '@/assets/images/misc/podcasting.jpg'

export default {
  name: "GetInTouchHeader",
  setup() {

     return {
        podcastingImg,
        topRightRedDot5X5GridBackground: {
          backgroundImage: `url(${redDotGrid5X5})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '100% 0%'
        },
        bottomLeftRed2X2DotGridBackground: {
          backgroundImage: `url(${redDotGrid2X2})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0% 100%'
        },
      }
  }
};
</script>