import { createStore } from 'vuex'
import { auth } from '../config/firebase'
import { 
  signInWithEmailAndPassword, 
  signOut,
  onAuthStateChanged
} from 'firebase/auth'

import { getDocuments,
         deleteDocument } from '../composables/firebaseFirestore';


// state
const store = createStore({
  state: {
    content: {
      podcasts: [],
      vlogs: [],
      blogs: [],
      temporary: {
        name: '',
        logo: {
          url: ''
        },
        description: '',
        episodesURL: '',
        featured: false,
        hosts: []
      },
      services: {
        podcasts: {
          packageDetails: `<ul>
                            <li>Full-service podcast launch and management</li>
                            <li>Marketing & Content</li>
                            <li>Video Services</li>
                            <li>Social Media management</li>
                            <li>Integration of Intros, Outros and Music</li>
                            <li>Professional audio engineering</li>
                            <li>Delivery of completed files to Google Drive</li>
                            <li>Podcast distribution</li>
                            <li>Show notes</li>
                            <li>Full episode edits</li>
                            <li>1 audio teaser clip</li>
                            <li>Customizable special effects</li>
                            <li>Marketing materials, flyers and audio clips</li>
                            <li>All for up to 4 episodes</li>
                          </ul>`
        },
        vlogs: {
          packageDetails: `<ul>
                            <li>Integration of Intros</li>
                            <li>Custom outro end screen</li>
                            <li>Royality free background music</li>
                            <li>Subscribe and Follow popups</li>
                            <li>Professional audio engineering</li>
                            <li>Trimming and merging</li>
                            <li>Partial subtitles</li>
                            <li>Video rendered in 1080P HD</li>
                            <li>1 video teaser clip</li>
                            <li>Delivery of completed files to Google Drive</li>
                            <li>Promotion (21 Media Instagram / Facebook)</li>
                            <li>Custom YouTube / Instagram thumbnail</li>
                            <li>SEO optimized video descriptions</li>
                            <li>Video uploading directly to YouTube channel</li>
                            <li>All for up to 4 episodes</li>
                        </ul>`
        }
      },
      profiles: [
        {
          id: '1',
          profileURL: 'drew-lasker',
          fullName: 'Drew Lasker',
          role: 'Co-Founder, President',
          image_url: 'https://firebasestorage.googleapis.com/v0/b/twenty-one-media.appspot.com/o/site-assets%2Fprofiles%2Fdrew-lasker.jpeg?alt=media&token=cf0d7a52-0665-4e0d-866c-edcef3e37bee',
          bio: '<p>When Drew retired from professional basketball after his 16th season, he started the next chapter of his career with the commitment to invest in the lives of youth, just like so many had done for him while he was growing up.</p><br /><p>21 Foundation was inspired by Drew’s personal commitment to help student athletes explore the various doors of opportunities that sports will open…even if they do not lead to a career as a professional athlete.</p><br /><p>You must, however, work hard and have faith in God.</p>',
          socialMediaURLS: {
                              linkedIn: 'https://www.linkedin.com/in/drewlasker/',
                              instagram: 'https://www.instagram.com/drewlasker21/',
                              facebook: 'https://www.facebook.com/drewlasker21'
                              // twitter: '#',
                            },
          type: 'founder'
        },
        {
          id: '2',
          profileURL: 'yvonne-harris',
          fullName: 'Yvonne Harris',
          role: 'Co-Founder, Executive Director',
          image_url: 'https://firebasestorage.googleapis.com/v0/b/twenty-one-media.appspot.com/o/site-assets%2Fprofiles%2Fyvonne-harris.jpeg?alt=media&token=fc1439d6-8339-4765-b6d6-b13d2bf7c384',
          bio: `<p>Yvonne N. Harris is a Diversity, Equity & Inclusion (DE&I) leader, Corporate Social Responsibility (CSR) champion, and a Human Resources (HR) expert.  She is also Co-Founder of 21 Media, LLC.</p><br> 
                <p>Prior to 21 Media, Yvonne was with a global leader in technology consulting and services company where she held the roles of <strong>North America Diversity & Inclusion Leader, Diversity Talent Attraction Leader, CSR Inclusion Brand Leader,</strong> and <strong>HR Leader</strong></p><br>
                <p>Yvonne is an experienced training facilitator and a frequent speaker at conferences and events on a variety of leadership topics including women\’s empowerment, unconscious bias, generational differences, gender equity, work-life integration, and inclusive leadership.</p><br>
                <p>Yvonne is a two-time winner of the National Diversity Council\’s <strong>DiversityFIRST Award</strong> which recognizes demonstrated dedication and continuing commitment to advocacy, raising awareness of diversity, and protecting civil and human rights. She also leads a number of volunteer projects within her community.</p>`,
          socialMediaURLS: {
                              linkedIn: 'https://www.linkedin.com/in/ynharris/',
                              facebook: 'https://www.facebook.com/yvonne.harris.92798'
                            },
          type: 'founder'
        },
        {
          id: '3',
          profileURL: 'seb',
          fullName: 'Seb Kennedy-Compston',
          role: 'Video & Audio Editor Intern',
          image_url: 'https://firebasestorage.googleapis.com/v0/b/twenty-one-media.appspot.com/o/site-assets%2Fprofiles%2Fseb-the-editor.jpg?alt=media&token=6c49837a-af62-4a03-a82f-45f2e7ea33ab',
          bio: '<p>Hi! I\'m Seb - a keen, aspiring journalist who absolutely loves watching and talking all things sports! I\'ve got two years of experience in the industry to date and, having interviewed some of the greatest stars of all time across a variety of different sports (primarily the NFL), my sponsored podcast \'Seb Talks Sports\' has grown into something I\'m incredibly proud of. I\'m excited to put my knowledge and skills to use at 21 Media and gain further experience as a member of a fantastic team.</p>',
          socialMediaURLS: {
                              // linkedIn: '#',
                              // instagram: '#',
                              // facebook: '#',
                              // twitter: '#'
                            },
          type: 'staff'
        },
        {
          id: '4',
          profileURL: 'corey-mallory',
          fullName: 'Corey Mallory',
          role: 'Executive Producer',
          image_url: 'https://firebasestorage.googleapis.com/v0/b/twenty-one-media.appspot.com/o/site-assets%2F21MediaPlaceHolderContentLogo.png?alt=media&token=0c51e4f6-169d-455c-8f82-d1d79bbc210c',
          bio: ' <p>My name is Corey Mallory and I am the Executive Producer for 21 Media. I grew up in Katy, Texas where my love of sports began. As a player and avid fan, I have followed and been involved with football, basketball, and other sports with a passion. I graduated from U of H Clear Lake with a bachelor’s degree focusing on Communications. I have previously worked for a local sports radio station before starting a family and pursuing a career in education and coaching.</p><br> <p>When my son was born my focus shifted to staying at home with him and becoming a work-from-home production consultant. I truly enjoy working with 21 Media and assisting in developing content and editing podcasts. In my spare time I spend time with my wife and children.</p>',
          socialMediaURLS: {
                              // linkedIn: '#',
                              // instagram: '#',
                              // facebook: '#',
                              // twitter: '#'
                            },
          type: 'staff'
        },
        {
          id: '5',
          profileURL: 'jonny-wong',
          fullName: 'Jonny Wong',
          role: 'Visual Producer',
          image_url: 'https://firebasestorage.googleapis.com/v0/b/twenty-one-media.appspot.com/o/site-assets%2Fprofiles%2Fjonny-wong.jpg?alt=media&token=4afe50b3-fc03-4e12-9ee3-b58f4623c86a',
          bio: '<p>My passion for video and content creation started in 2016 whilst studying Mechanical Engineering,  co-creating a YouTube channel with my friend and professional Goalkeeper Conor O’Keefe, documenting his professional football career – with my responsibility being the production of all vlog episodes as videographer and video editor.</p><br><p>The opportunity came to learn more about video production on set and in the editing room from a Director of Production who became a mentor of mine. I began freelancing whilst continuing to study at university and assisting on sets.</p><br><p>After graduating university, I made the decision to follow my passion of video and content production full-time leading to a variety of creative projects with some well-known brands and interesting individuals.</p><br><p>In early 2022 the YouTube channel started at university with long-time friend Conor O’Keefe had grown a community of over 61,000 subscribers and had provided a deeper understanding of how you can make improvements to a social media platform.</p><br><p>Given my experience with YouTube and video production my role with 21 Media sees me acting as a consulting freelance video producer who offers guidance for the direction of video podcasts, such as the BBL Show, by providing constructive advice backed up by past experiences and through interpretation of platform analytics. I also provide technical knowledge regarding the hardware and software required to advance the quality of productions to bring visual and audio standards in line with others in the industry.</p>',
          socialMediaURLS: {
                              // linkedIn: '#',
                              // instagram: '#',
                              // facebook: '#',
                              // twitter: '#'
                            },
          type: 'staff'
        }
      ]
    },
    displayLoadingSpinner: null,
    user: null,
    authIsReady: false
  },
  mutations: {

    addContent(state, payload) {
      switch(payload.type) {
        case 'podcast':
          state.content.podcasts.unshift(payload)
          break

        case 'vlog':
          state.content.vlogs.unshift(payload)
          break
        
        default:
          break
      }
      
    },

    // store > 'temporary' show object
    setTemporaryShowState(state, payload) {
      state.content.temporary.name = payload.name
      state.content.temporary.logo.url = payload.logo_url
      state.content.temporary.description = payload.description
      state.content.temporary.episodesURL = payload.episodesURL
      state.content.temporary.featured = payload.featured
      state.content.temporary.hosts = payload.hosts
    },

    resetTemporaryShowState(state) {
      state.content.temporary.name = ''
      state.content.temporary.logo.url = ''
      state.content.temporary.description = ''
      state.content.temporary.episodesURL = ''
      state.content.temporary.featured = ''
      state.content.temporary.hosts = []
    },


    // Podcasts
    sortPodcastsByNameDesc(state) {
      state.content.podcasts.sort((a, b) => b.name - a.name);
    },
    sortPodcastsByupdatedAtDesc(state) {
      state.content.podcasts.sort((a, b) => b.updatedAt - a.updatedAt);
    },


    // Vlogs
    sortVlogsByNameDesc(state) {
      state.content.vlogs.sort((a, b) => b.name - a.name);
    },
    sortVlogsByupdatedAtDesc(state) {
      state.content.vlogs.sort((a, b) => b.updatedAt - a.updatedAt);
    },

    removeContentFromStore(state, { type, id } = payload) {

        switch(type) {
          case 'podcast': 
            state.content.podcasts = state.content.podcasts.filter(podcast => podcast.id !== id)
            break
  
          case 'vlog': 
            state.content.vlogs = state.content.vlogs.filter(vlog => vlog.id !== id)
            break
          
          // case 'blog': 
          //   state.content.blogs = state.content.blogs.filter(blog => blog.id !== id)
          //   break
  
          default:
            break
        }
    },

    // Blogs - UNCOMMENT TO ADD BLOG FUNCTIONALITY 
    /*
    addBlog(state, payload) {
      state.content.blogs.unshift(payload)
    },
    sortBlogsByNameDesc(state) {
      state.content.blogs.sort((a, b) => b.name - a.name);
    },
    sortBlogsByupdatedAtDesc(state) {
      state.content.blogs.sort((a, b) => b.updatedAt - a.updatedAt);
    },
    */
   
    // Used in Add/Create Podcast/Vlog etc
    addShowName(state, payload) {
      state.content.temporary.name = payload
    },

    createLogoURL(state, payload) {
      state.content.temporary.logo.url = payload
    },

    showDescription(state, payload) {
      state.content.temporary.description = payload
    },

    addShowEpisodesURL(state, payload) {
      state.content.temporary.episodesURL = payload
    },

    featured(state, payload) {
      state.content.temporary.featured = payload
    },

    addHost(state, payload) {
      state.content.temporary.hosts.push(payload)
    },

    removeHost(state, index) {
      state.content.temporary.hosts.splice(index, 1)
    },

    removeInvalidHosts(state) {
      state.content.temporary.hosts = state.content.temporary.hosts.filter(host => (host.fullName !== undefined && host.bio !== undefined))
    },


    toggleLoadingSpinner(state) {
      state.displayLoadingSpinner = !state.displayLoadingSpinner
    },


    // Profiles
    addProfile(state, payload) {
      state.content.profiles.unshift(payload)
    },
    sortProfilesByNameDesc(state) {
      state.content.profiles.sort((a, b) => b.fullName - a.fullName);
    },

 
    // user
    setUser(state, payload) {
      state.user = payload
    },

    setAuthIsReady(state, payload) {
      state.authIsReady = payload
    }
  },
  getters: {

    /*
      type = 'all', 'podcasts', 'vlogs', 
      
      Also, 'blogs' - commented out until type required
    */
    featuredContent: state => type => {
      const featuredPodcasts = state.content.podcasts.filter(podcast => podcast.featured === true)
      const featuredVlogs = state.content.vlogs.filter(vlog => vlog.featured === true)
      // const featuredBlogs = state.content.blogs.filter(blog => blog.featured === true)
      let featuredContent = []

      switch(type) {
        case 'all':
          featuredContent = [...featuredPodcasts, ...featuredVlogs]
          break

        case 'podcasts':
          featuredContent = [...featuredPodcasts]
          break

        case 'vlogs':
          featuredContent = [...featuredVlogs]
          break

        // case 'blogs':
        //   featuredContent = [...featuredBlogs]
        //   break
        
        default:
          // same as all
          featuredContent = [...featuredPodcasts, ...featuredVlogs]
          break
      }

      const randomPositionInArray = Math.floor(Math.random() * featuredContent.length)

      return featuredContent[randomPositionInArray]
    },

    // returns a specific Podcast, Vlog, Blog item
    content: state => (type, id) => {
      let content = undefined;

      switch(type) {
        case 'podcast': 
          content = state.content.podcasts.filter(podcast => podcast.id === id)
          break

        case 'vlog': 
          content = state.content.vlogs.filter(vlog => vlog.id === id)
          break
        
        // case 'blog': 
        //   content = state.content.blogs.filter(blog => blog.id === id)
        //   break

        default:
          break
      }

      return content[0]
    },

    // return Podcasts - all or from - to
    podcasts: state => (all = false, fromPodcast = 0, toPodcast = 3) =>  all ? state.content.podcasts : state.content.podcasts.slice(fromPodcast, toPodcast),

    // return Vlogs - all or from - to
    vlogs: state => (all = false, fromVlog = 0, toVlog = 3) => all ? state.content.vlogs : state.content.vlogs.slice(fromVlog, toVlog),

    // Blogs - UNCOMMENT TO ADD BLOG FUNCTIONALITY 
    /*
    // return Blogs - all or from - to
    blogs: state => (all = false, fromBlog = 0, toBlog = 3) => all ? state.content.blogs : state.content.blogs.slice(fromBlog, toBlog),
    */

    logoURL: state => state.content.temporary.logo.url,

    /*
      services
    */
    servicesContent: state => type => {
      let content = undefined;

      switch(type) {
        case 'podcasts': 
          content = state.content.services.podcasts
          break

        case 'vlogs': 
          content = state.content.services.vlogs
          break

        default:
          break
      }

      return content
    },



    /*
      team
    */
    profile: state => id => id ? state.content.profiles.filter(profile => profile.id === id) : undefined,
    profileByName: state => name => state.content.profiles.filter(profile => profile.profileURL === name)[0],
    profilesByType: state => type => state.content.profiles.filter(profile => profile.type === type),

    // user
    user: state => state.user
  },
  actions: {
    // called in app.vue on first load of the application and on any page hard refresh

    // Podcasts
    getPodcastsFromDb: async ({ commit }) => { 

      const podcastsFromDb = await getDocuments(store, { 
                                          collectionName: 'podcasts',
                                          orderByField: 'createdAt',
                                          orderByDirection: 'desc'
                                        })

        podcastsFromDb.forEach(doc => {

          if(!store.state.content.podcasts.some(podcast => podcast.id === doc.id)) {

              let podcast = {
                id: doc.id,
                createdAt: doc.data().createdAt,
                description: doc.data().description,
                episodesURL: doc.data().episodesURL,
                featured: doc.data().featured,
                hosts: doc.data().hosts,
                logo_url: doc.data().logo_url,
                name: doc.data().name,
                type: doc.data().type,
                updatedAt: doc.data().updatedAt
              } 

              // add current Podcast to the VueX state
              commit('addContent', podcast)
              
              podcast = null
          }     
      });
          
      // sort the Podcast in the VueX state, in reverse order by name field value
      commit('sortPodcastsByNameDesc')
    },

    // Vlogs
    getVlogsFromDb: async ({ commit }) => { 

      const vlogsFromDb = await getDocuments(store, { 
                                          collectionName: 'vlogs',
                                          orderByField: 'createdAt',
                                          orderByDirection: 'desc'
                                        })

        vlogsFromDb.forEach(doc => {

          if(!store.state.content.vlogs.some(vlog => vlog.id === doc.id)) {

            let vlog = {
              id: doc.id,
              createdAt: doc.data().createdAt,
              description: doc.data().description,
              episodesURL: doc.data().episodesURL,
              featured: doc.data().featured,
              hosts: doc.data().hosts,
              logo_url: doc.data().logo_url,
              name: doc.data().name,
              type: doc.data().type,
              updatedAt: doc.data().updatedAt
            } 
 
            // add current Vlog to the VueX state
            commit('addContent', vlog)
            
            vlog = null
          }     
      });
          
      // sort the Vlogs in the VueX state, in reverse order by name field value
      commit('sortVlogsByNameDesc')
    },

    deleteContent: async ({ commit }, { type, id } = show) => {

      // delete article from Firebase Firestore
      deleteDocument(store, {
                      collectionName: type, 
                      id
                    })

      // remove article from VueX state           
      commit('removeContentFromStore', { type, id } )
    },

    updateContent: async ({ commit, dispatch }, { type, id }) => {

      // remove article from VueX state           
      commit('removeContentFromStore', { type, id })

      // get either the Podcasts, Vlogs or Blogs from the Firebase Firestore with the newly updated content
      switch(type) {
        case 'podcast':
          await dispatch('getPodcastsFromDb')
          break

        case 'vlog':
          await dispatch('getVlogsFromDb')
          break

        // case 'blog':
        //   await dispatch('getBlogsFromDb')
        //   break

       default:
         break
      }
    },


    // Blogs - UNCOMMENT TO ADD BLOG FUNCTIONALITY 
    /*
    getBlogsFromDb: async ({ commit }) => { 

      const blogsFromDb = await getDocuments(store, { 
                                          collectionName: 'blogs',
                                          orderByField: 'createdAt',
                                          orderByDirection: 'desc'
                                        })

        blogsFromDb.forEach(doc => {

          if(!store.state.content.blogs.some(blog => blog.id === doc.id)) {

            let blog = {
              id: doc.id,
              createdAt: doc.data().createdAt,
              description: doc.data().description,
              facebook: doc.data().facebook,
              featured: doc.data().featured,
              authors: doc.data().authors,
              instagram: doc.data().instagram,
              logo_url: doc.data().logo_url,
              name: doc.data().name,
              shortDescription: doc.data().shortDescription,
              twitter: doc.data().twitter,
              updatedAt: doc.data().updatedAt,
              url: doc.data().url,
              youtube: doc.data().youtube
            } 
              
            // add current article to the VueX state
            commit('addBlog', blog)
            
            blog = null
          }     
      });
          
      // sort the Blogs in the VueX state, in reverse order by name field value
      commit('sortBlogsByNameDesc')
    },
    */

    // staff

    // called in app.vue on first load of the application and on any page hard refresh
    getStaffFromDb: async ({ commit }) => { 
      const staffFromDb = await getDocuments(store, { collectionName: 'staff' })

      staffFromDb.forEach(doc => {

        if(!store.state.staff.some(member => member.id === doc.id)) {

            let member = {
                id: doc.id,
                firstName: doc.data().firstName,
                lastName: doc.data().lastName,
                role: doc.data().role,
                profilePictureURL: doc.data().profilePictureURL,
                profilePictureFileName: doc.data().profilePictureFileName,
                bio: doc.data().bio,
                content: doc.data().content,
                createdAt: doc.data().createdAt
            } 
            
            // add current article to the VueX state
            commit('addStaffMember', member)

            member = null
        }     
      });
    },

    updateStaffMember: async ({ commit, dispatch }, payload) => {

      // remove staff member from VueX state           
      commit('removeStaffMemberFromStaffMembersArray', payload)

      // get the staff members from the Firebase Firestore with the newly updated content
      await dispatch('getStaffFromDb')
    },

    deleteStaffMember: async ({ commit }, { id }) => {

      // delete staff member from Firebase Firestore
      deleteDocument(store, {
                      collectionName: 'staff', 
                      id: id
                    })

      // remove staff member from VueX state           
      commit('removeStaffMemberFromStaffMembersArray', id)
    },

    // user
    async login(context, { email, password }) {      
      // async code
      const res = await signInWithEmailAndPassword(auth, email, password)

      if(res) {
          context.commit('setUser', res.user)
      } else {
          throw new Error('could not complete login')
      }
    },

    async logout(context) {
        await signOut(auth)
        context.commit('setUser', null)
    }
  },
  modules: {
  }
})

/*
    automattically fired when application first loads

    - user = the Firebase user object - if user is logged in 
    - user = null - if the user is not logged in
*/
const unsub = onAuthStateChanged(auth, (user) => {
  store.commit('setAuthIsReady', true)
  store.commit('setUser', user)
  // without this, the function would be called in an endless loop
  unsub()
})

export default store