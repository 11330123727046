<template>

<div class="modal">

    <div class="mb-4
                mx-4
                flex
                flex-col
                justify-between
                rounded-xl
                overflow-hidden
                bg-white
                shadow-xl 
                shadow-grey">

            <span class="p-6">

              <h2 class="text-sm
                         md:text-lg
                         font-bold
                         truncate
                         flex
                         flex-col
                         md:flex-row
                         items-center
                         justify-center">

               <img class="h-5 
                           w-5
                           mb-2
                           md:mb-0
                           md:mr-2
                           " 
                    :src="warningIcon" 
                    alt="warning icon" /> {{ this.modalMessage }}

              </h2>

            </span>   

            <span class="flex 
                         border-t 
                         border-backgroundgrey
                         justify-center 
                         p-1
                         bg-backgroundgrey">

              <button class="h-10 
                            mx-auto
                            rounded-md 
                            p-2
                            transition 
                            duration-300  
                            ease-in-out
                            flex"
                        @click="cancelButtonClicked">

                <img class="h-5 
                            w-5" 
                    :src="cancelIcon" 
                    alt="Edit icon" />

                <p class="text-sm
                          pl-2
                          text-white
                        hover:text-red
                          transition 
                          duration-300  
                          ease-in-out
                          font-semibold">
                  Cancel
                </p>
                
              </button>

              <button class="h-10 
                             mx-auto
                             rounded-md 
                             p-2
                             focus-within: transition 
                             duration-300  
                             ease-in-out
                             flex"
                      @click="buttonClicked">

                <img class="h-5 w-5" :src="deleteIcon" alt="Delete icon" />

                <p class="text-sm
                          pl-2
                          text-white
                          hover:text-red
                          transition 
                          duration-300  
                          ease-in-out
                          font-semibold">
                  {{ buttonText }}
                </p>

              </button>

            </span>
    
          </div>
    
    </div>

</template>

<script>
import Button from './Button.vue'
import warningIcon from '@/assets/images/icons/warning-icon.svg'
import cancelIcon from '@/assets/images/icons/cancel-icon.svg'
import deleteIcon from '@/assets/images/icons/trash-regular.svg'

export default {
    components: {
        Button
    },
    props: [
        'modalMessage',
        'buttonText'
    ],
    methods: {
        cancelButtonClicked() {
            this.$emit('cancel-button-clicked')
        },

        buttonClicked() {
            this.$emit('button-clicked')
        }
    },
    setup() {
        return {
            warningIcon,
            cancelIcon,
            deleteIcon
        }
    }
}
</script>

<style>
  .modal {
      top: 0;
      left: 0;
      z-index: 101;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
    }
</style>