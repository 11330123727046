<template>

  <div class="grid 
              grid-flow-col
              grid-cols-1">

    <h2 class="font-regular
              text-3xl
              md:text-3xl">
      {{ name }}<span class="text-red">.</span>
    </h2>

    <section class="grid
                    mb-10
                    mt-14">

      <p class="text-base
                mt-2
                mb-8"
         v-html="description" />

      <router-link class="self-end" 
                    :to="{ name: linkDestination }">
        <Button buttonText="LEARN MORE"
                :paddingX="4"
                :paddingY="2" />
      </router-link>   

    </section>

  </div>

</template>

<script>
import Button from '@/components/Button.vue'

export default {
    name: 'Service',
    props: [
      'name',
      'description'
    ],
    components: {
      Button
    },
    setup(props) {
        const name = props.name
        const description = props.description
        const linkDestination = `Services${props.name}`

        return {
            name,
            description,
            linkDestination
        }
    }
}
</script>