import Home from '../views/Home.vue'
import store from '../store'
import { computed } from 'vue'
import { auth } from '../config/firebase'

// vue-router
import { createRouter, 
         createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/shows',
    redirect: '/shows/podcasts',
    name: 'Shows',
    meta: {
      title: 'Shows',
    },
    component: () => import(/* webpackChunkName: "Shows" */ '../views/shows/Shows.vue')
  },
  {
    path: '/shows/podcasts',
    name: 'ShowsPodcasts',
    meta: {
      title: 'Shows - Podcasts',
    },
    component: () => import(/* webpackChunkName: "ShowsPodcasts" */ '../views/shows/ShowsPodcasts.vue')
  },
  {
    path: '/shows/vlogs',
    name: 'ShowsVlogs',
    meta: {
      title: 'Shows - Vlogs',
    },
    component: () => import(/* webpackChunkName: "ShowsVlogs" */ '../views/shows/ShowsVlogs.vue')
  },
  {
    path: '/shows/:type/:id',
    name: 'ShowDetail',
    props: true,
    meta: {
      title: 'Shows'
    },
    component: () => import(/* webpackChunkName: "ShowDetail" */ '../views/shows/ShowDetail.vue')
  },
  {
    path: '/services',
    redirect: '/services/podcasts',
    name: 'Services',
    meta: {
      title: 'Services',
    },
    component: () => import(/* webpackChunkName: "Services" */ '../views/services/Services.vue')
  },
  {
    path: '/services/podcasts',
    name: 'ServicesPodcasts',
    meta: {
      title: 'Services - Podcasts',
    },
    component: () => import(/* webpackChunkName: "ServicesPodcasts" */ '../views/services/ServicesPodcasts.vue')
  },
  {
    path: '/services/vlogs',
    name: 'ServicesVlogs',
    meta: {
      title: 'Services - Vlogs',
    },
    component: () => import(/* webpackChunkName: "ServicesVlogs" */ '../views/services/ServicesVlogs.vue')
  },
  {
    path: '/team',
    name: 'Team',
    meta: {
      title: 'Team'
    },
    component: () => import(/* webpackChunkName: "Team" */ '../views/team/Team.vue')
  },
  {
    path: '/team/:id',
    name: 'ProfileDetail',
    props: true,
    meta: {
      title: 'Team'
    },
    component: () => import(/* webpackChunkName: "ProfileDetail" */ '../views/team/ProfileDetail.vue')
  },
  {
    path: '/get-in-touch',
    name: 'GetInTouch',
    meta: {
      title: 'Get in touch'
    },
    component: () => import(/* webpackChunkName: "GetInTouch" */ '../views/GetInTouch.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      title: 'Admin',
      requiresLogin: true
    },
    component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Admin.vue'),
    children: [
      {
        path: '/admin/shows',
        name: 'AdminShows',
        redirect: '/admin/shows/podcasts',
        meta: {
          title: 'Admin | Shows',
          requiresLogin: true
        },
        component: () => import(/* webpackChunkName: "AdminShowsPodcasts" */ '../views/admin/shows/AdminShowsPodcasts.vue')
      },
      {
        path: '/admin/shows/podcasts',
        name: 'AdminShowsPodcasts',
        meta: {
          title: 'Admin | Shows - Podcasts',
          requiresLogin: true
        },
        component: () => import(/* webpackChunkName: "AdminShowsPodcasts" */ '../views/admin/shows/AdminShowsPodcasts.vue')
      },
      {
        path: '/admin/shows/vlogs',
        name: 'AdminShowsVlogs',
        meta: {
          title: 'Admin | Shows - Vlogs',
          requiresLogin: true
        },
        component: () => import(/* webpackChunkName: "AdminShowsVlogs" */ '../views/admin/shows/AdminShowsVlogs.vue')
      },
      {
        path: '/admin/shows/create/podcast',
        name: 'AdminCreatePodcast',
        meta: {
          title: 'Create Show',
          requiresLogin: true
        },
        component: () => import(/* webpackChunkName: "AdminCreatePodcast" */ '../views/admin/shows/AdminCreatePodcast.vue')
      },
      {
        path: '/admin/shows/create/vlog',
        name: 'AdminCreateVlog',
        meta: {
          title: 'Create Show',
          requiresLogin: true
        },
        component: () => import(/* webpackChunkName: "AdminCreateVlog" */ '../views/admin/shows/AdminCreateVlog.vue')
      },
      {
        path: '/admin/shows/edit/podcast/:id',
        name: 'AdminEditPodcast',
        meta: {
          title: 'Edit Podcast',
          requiresLogin: true
        },
        component: () => import(/* webpackChunkName: "AdminEditPodcast" */ '../views/admin/shows/AdminEditPodcast.vue')
      },
      {
        path: '/admin/shows/edit/vlog/:id',
        name: 'AdminEditVlog',
        meta: {
          title: 'Edit Vlog',
          requiresLogin: true
        },
        component: () => import(/* webpackChunkName: "AdminEditVlog" */ '../views/admin/shows/AdminEditVlog.vue')
      }]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Login'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../views/admin/authentication/Login.vue')
  },
  {
    // catch all - 4O4
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      title: 'Not Found'
    },
    component: () => import(/* webpackChunkName: "NotFound" */ '../components/NotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 100
      }
    }
    else {
      // always scroll to top
      return { 
        top: 0
      }
    }
  }
})

router.beforeEach(async (to, from, next) => {

  // set page title 
  document.title = `${to.meta.title} | 21 Media`
  
  // get the key
  const authUserKey = Object.keys(window.sessionStorage)
    .filter(item => item.startsWith('firebase:authUser'))[0]

  // if a session cookie is present, i.e. a user is signed in. Then, use the key to get the user object value from the session cookie
  const authUser = authUserKey ? JSON.parse(window.sessionStorage.getItem(authUserKey)) : undefined;

  // console.log('window.sessionStorage authUser', authUser)
  if(to.matched.some(record => record.meta.requiresLogin) && authUser == null) {
    next({ name: 'Login' })
  } else {
    next()
  }
  
})

export default router
