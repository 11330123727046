<template>
    <a :href="'#' + to" class="outline-0">
        <img :src="downArrow" 
            alt="down arrow"
            class="w-8 
                   my-20 
                   mx-auto
                   motion-safe:animate-bounce" />
    </a>
</template>

<script>
import downArrow from '@/assets/images/misc/down-arrow-red-dot.svg'

export default {
    name: 'DownButton',
    props: ['to'],
    setup(props) {
        const to = props.to

        return {
            downArrow,
            to
        }
    }
}
</script>