<template>
    <p @click="back" 
        class="flex 
               items-center
               navigationLink
              text-darkgrey
               font-semibold">
      &#60; Back
    </p>
</template>

<script>
import backArrow from '@/assets/images/misc/back-arrow-red-dot.svg'

// vue-router
import { useRouter } from 'vue-router'

export default {
  name: 'BackButton',
  setup () {
      const router = useRouter()

      const back = () => router.go(-1)
      
      return {
          backArrow,
          back
        }
  },
}
</script>