<template>

  <div class="flex 
              flex-col 
              bg-gradient-to-b 
              from-verylightgrey 
              to-white
              mx-auto
              pb-20">

      <div class="mx-auto
                  text-center">

        <img class="w-18
                    mx-auto
                    mt-20
                    mb-10" 
            :src="logoIcon" 
              alt="Logo icon" />

        <h1 class="text-3xl
                   mx-auto
                   mb-10
                  text-grey">Page Not Found</h1>

        <p class="mb-4">Sorry, the page you are looking for cannot be found.</p>

        <p>Click <router-link class="navigationLink" :to="{ name: 'Home' }">here</router-link> to visit the homepage.</p>
        
      </div>

    </div>

</template>
  
<script>
import logoIcon from '@/assets/images/logo/21MediaLogoWhite.svg'

export default {
  name: "NotFound",
  setup() {
    return {
      logoIcon
    }
  }
};
</script>
