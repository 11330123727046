<template>

  <div v-if="authIsReady" 
       class="md:flex
              min-h-full
              w-full
              h-full
              mb-10">

    <AdminMenu />
    
    <div class="w-full md:mt-24 lg:mt-0">

      <div class="flex 
                  p-10
                  justify-end
                  md:ml-28">

        <a @click="signOut"
            class="cursor-pointer 
                   flex 
                   items-center">

          <p class="text-darkgrey
                    font-semibold
                    navigationLink
                    pr-2">
            Sign Out
          </p>
          
          <img class="h-5"
              :src="logoutIcon" 
                 alt="logout icon" />

        </a>
 
      </div>

      <!-- main content area -->
      <main class="px-10
                   pb-10
                   lg:w-3/4
                   xl:w-4/6
                   md:mx-auto
                   lg:ml-52">

        <router-view></router-view>
        
      </main>
    
    </div>

  </div>

</template>

<script>
import logoutIcon from '@/assets/images/icons/logout-icon.svg'
import AdminMenu from '../../components/AdminMenu.vue'

// vue
import { computed } from 'vue'

// vuex
import { useStore } from 'vuex'

// vue-router
import { useRouter } from 'vue-router'

export default {
  name: 'Admin',
  components: {
    AdminMenu
  },
  setup() {

    const store = useStore()
    const router = useRouter()

    const signOut = computed(() => {
        store.dispatch('logout')
        
        // redirect to the homepage after signout
        router.push({ 
            name: 'Home',
        })
    })

    return {
      user: computed(() => store.state.user),
      authIsReady: computed(() => store.state.authIsReady),
      logoutIcon,
      signOut
    }
  }
}
</script>
