<template>
  <nav class="font-semibold
              text-lg
              tracking-wide
              w-full
              lg:w-32
              md:fixed
              lg:h-full
              p-4
              bg-white
              flex
              flex-col
              sticky 
              top-0
              z-100">

            <!-- Nav Links -->
            <div class="hidden 
                        lg:block
                        divide-lightgrey">
                <router-link class="header" 
                             :to="{ name: 'AdminShows' }">
                    <img :src="logo" 
                          alt="down arrow"
                          class="h-10 
                                 mt-2 
                                 mb-6 
                                 mx-auto" />
                </router-link>

                <section class="flex
                                flex-col
                                py-10
                                border-t 
                                border-darkgrey">

                  <router-link class="navigationLinkDark
                                      text-base
                                      mb-4
                                      adminPodcasts" 
                              :to="{ name: 'AdminShowsPodcasts' }">Podcasts<span class="text-red">.</span></router-link>
                            
                  <router-link class="navigationLinkDark
                                      text-base
                                      mb-4
                                      adminVlogs" 
                              :to="{ name: 'AdminShowsVlogs' }">Vlogs<span class="text-red">.</span></router-link>

                </section>
                
            </div>


            <!-- Mobile navigation button-->
            <div class="lg:hidden 
                        flex 
                        min-w-full 
                        justify-between
                        pb-2">

                <router-link class="header" 
                             :to="{ name: 'AdminShows' }"
                             @click="toggleMobileNavigationMenu">
                    <img class="w-10 
                                mr-20
                                cursor-pointer" 
                         :src="logo" 
                          alt="Logo" />
                </router-link>

                <MobileNavigationButton @toggleMobileNavigationMenu="toggleMobileNavigationMenu" />
            </div>

          <!-- Mobile navigation menu-->
          <div :class="{ hidden: hideMobileNavigationMenu }" 
                class="lg:hidden
                        mt-6">
              <section class="flex
                              flex-col">

                <router-link class="navigationLinkDark
                                    text-base
                                    mb-4
                                    adminPodcasts" 
                              :to="{ name: 'AdminShowsPodcasts' }"
                              @click="toggleMobileNavigationMenu">Podcasts<span class="text-red">.</span></router-link>
                          
                <router-link class="navigationLinkDark
                                    text-base
                                    mb-4
                                    adminVlogs" 
                              :to="{ name: 'AdminShowsVlogs' }"
                              @click="toggleMobileNavigationMenu">Vlogs<span class="text-red">.</span></router-link>

              </section>

          </div>

    </nav>

</template>

<script>
import logo from '@/assets/images/logo/21-logo-icon.svg'

import MobileNavigationButton from './MobileNavigationButton.vue'

// vue
import { ref } from 'vue'

export default {
  name: 'AdminMenu',
  components: {
    MobileNavigationButton
  },
  setup() {
    let hideMobileNavigationMenu = ref(true)
    
    const toggleMobileNavigationMenu = () => hideMobileNavigationMenu.value = !hideMobileNavigationMenu.value

    return {
        logo,
        hideMobileNavigationMenu,
        toggleMobileNavigationMenu
    }
  }
}
</script>

