<template>

  <header class="flex 
                 justify-between">

    <div class="w-full
                md:w-1/2
                mt-12">

      <h1 class="font-semibold
                 text-4xl
                 xl:text-5xl">
        PODCASTS<span class="text-red">.</span>
      </h1>
      
      <p class="text-xl
                xl:text-lg 
                font-regular
                mb-10">
                Our packages enable content creators to publish professional quality podcasts whilst freeing you up to focus content creation.
      </p>
      <p class="text-xl
                xl:text-lg 
                font-regular
                mb-10">
                We offer a variety of services including, professional audio engineering, editing, show notes and much more. 
      </p>

    </div>
    
     <div :style="topRightRedDot5X5GridBackground" 
          class="hidden 
                 md:block 
                 ml-10">
      <div :style="bottomLeftRed2X2DotGridBackground">
        <div class="p-14">
          <img :src="podcastsImg" 
                class="p-0 
                      md:p-0 
                      rounded-tl-2xl
                      rounded-br-2xl" />
        </div>
      </div>
     </div>

  </header>

</template>

<script>
import redDotGrid5X5 from '@/assets/images/misc/5x5-red-dot-grid.svg'
import redDotGrid2X2 from '@/assets/images/misc/2x2-red-dot-grid.svg'
import podcastsImg from '@/assets/images/misc/podcasting.jpg'

import Mission from './Mission.vue'
import SocialMediaLinks from './SocialMediaLinks.vue'
import Button from './Button.vue'

export default {
  name: "ServicesPodcastsHeader",
  components: {
    Mission,
    SocialMediaLinks,
    Button
  },
  setup() {

     return {
        podcastsImg,
        topRightRedDot5X5GridBackground: {
          backgroundImage: `url(${redDotGrid5X5})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '100% 0%'
        },
        bottomLeftRed2X2DotGridBackground: {
          backgroundImage: `url(${redDotGrid2X2})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0% 100%'
        },
      }
  }
};
</script>