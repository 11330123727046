<template>

  <div v-if="displayingInFooter" class="flex">

    <span class="flex mx-auto gap-x-4">

      <a v-if="linkedInURL" :href="linkedInURL">
        <img class="h-12 w-auto text-red mt-2" :src="linkedInLogo" alt="LinkedIn icon" />
      </a>
      <a v-if="facebookURL" :href="facebookURL">
        <img class="h-12 w-auto" :src="facebookLogo" alt="Facebook icon" />
      </a>
      <a v-if="instagramURL" :href="instagramURL">
        <img class="h-12 w-auto" :src="instagramLogo" alt="Instagram icon" />
      </a>
      <a v-if="twitterURL" :href="twitterURL">
        <img class="h-12 w-auto" :src="twitterLogo" alt="Twitter icon" />
      </a>

    </span>

  </div>
  <span v-else class="flex" >
    <a v-if="linkedInURL" :href="linkedInURL">
      <img class="h-10 w-auto text-red mt-2" :src="linkedInLogo" alt="LinkedIn icon" />
    </a>
    <a v-if="facebookURL" :href="facebookURL">
      <img class="h-10 w-auto" :src="facebookLogo" alt="Facebook icon" />
    </a>
    <a v-if="instagramURL" :href="instagramURL">
      <img class="h-10 w-auto" :src="instagramLogo" alt="Instagram icon" />
    </a>
    <a v-if="twitterURL" :href="twitterURL">
      <img class="h-10 w-auto" :src="twitterLogo" alt="Twitter icon" />
    </a>
  </span>
  
</template>

<script>
// vue
import { ref } from 'vue'

import linkedInLogo from '@/assets/images/logo/socialMediaLogos/linkedinLogo.svg'
import instagramLogo from '@/assets/images/logo/socialMediaLogos/instagramLogo.svg'
import twitterLogo from '@/assets/images/logo/socialMediaLogos/twitterLogo.svg'
import facebookLogo from '@/assets/images/logo/socialMediaLogos/facebookLogo.svg'

export default {
  name: 'SocialMediaLinks',
  props: [
    'displayingInFooter',
    'socialMediaURLS'
  ],
  setup(props) {
    const displayingInFooter = ref(props.displayingInFooter)
    const linkedInURL = ref(props.socialMediaURLS.linkedIn)
    const instagramURL = ref(props.socialMediaURLS.instagram)
    const twitterURL = ref(props.socialMediaURLS.twitter)
    const facebookURL = ref(props.socialMediaURLS.facebook)

    return {
      displayingInFooter,
      linkedInURL,
      instagramURL,
      twitterURL,
      facebookURL,
      linkedInLogo,
      instagramLogo,
      twitterLogo,
      facebookLogo
    }
  }
}
</script>

