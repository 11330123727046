<template>

  <Modal modalMessage="Do you wish to permanently delete the show?" 
         buttonText="Yes, delete it"
         v-if="displayModal"
         v-on:cancel-button-clicked="toggleModal"
         v-on:button-clicked="deleteShow" /> 

  <BackButton />

  <div class="md:flex 
              md:justify-between 
              md:items-end">

     <h2 class="font-regular
                text-3xl
                mt-8
                md:text-3xl">
      Edit vlog<span class="text-red">.</span>
     </h2>
  
    <button @click="toggleModal()" 
             class="h-10 
                    rounded-md 
                    md:p-2
                    transition 
                    duration-300  
                    ease-in-out
                    flex
                    justify-self-end
                    mt-8
                    md:mt-0">

        <img class="h-5 
                    w-5" 
             :src="deleteIcon" 
             alt="Delete icon" />
          
        <p class="font-bold 
                  ml-2
                  navigationLink
                  text-grey">Delete Show</p>
    </button>

  </div>

  <div :class="{ invisible: !error }" 
        class="err-message">
    <p>
      <span>Error:</span>{{ errorMsg }}
    </p>
  </div>

  <Loading class="fixed 
                  left-0 
                  top-0" 
           v-show="this.$store.state.displayLoadingSpinner" />

  <!-- name -->
  <div class="mt-4">
    <p class="text-l 
              font-bold">
      Show Name
    </p>
    <input type="text" 
           placeholder="Enter the show name" 
           v-model="name"
           class="w-full
                  p-2
                  mt-2
                  mb-6
                  border-none
                bg-white">
  </div>

  <!-- show logo file chooser and upload -->
  <div>

    <div class="items-center
                bg-darkgrey
                hover:bg-red
                text-white
                  text-center
                  rounded-full
                  py-2
                  px-4
                  mr-4
                  my-6
                  transition 
                  duration-300 
                  ease-in-out
                  cursor-pointer
                  w-36">

      <label for="show-logo" 
             class="text-center 
                    cursor-pointer
                    font-semibold">
        Add show logo
      </label>
      
    </div>

    <input type="file" 
           ref="logo" 
           id="show-logo"
           accept=".png, .jpg, .jpeg"
           @change="logoFileChanged"
           class="hidden">

   <img v-if="this.$store.state.content.temporary.logo.url" 
        :src="this.$store.state.content.temporary.logo.url" 
        alt="Logo"
        class="h-auto
               w-52
               rounded-lg">

  </div>

  <!-- description -->
  <p class="text-l 
            font-bold
            mt-10">Description</p>

  <div class="mt-2
              bg-white">
    <QuillEditor @contentChanged="editorContentChanged" 
                  :content="show.description" 
                  class="text-darkgrey"/>
  </div>

  <!-- episodesURL -->
  <div class="mt-10">
    <p class="text-l 
              font-bold">
      Watch All Episodes button link
    </p>
    <input type="text" 
           placeholder="Example: https://www.youtube.com" 
           v-model="episodesURL"
           class="w-full
                  p-2
                  mt-2
                  mb-6
                  border-none
                bg-white">
  </div>  

  <!-- featured --> 
  <div class="my-4">
    <input id="featuredCheckbox"
           v-model="featuredCheckbox"
           type="checkbox"
          :checked="featuredCheckbox">
    <label for="featuredCheckbox"
           class="font-bold">
      Include in featured vlogs
    </label>
  </div>

  <!-- hosts -->
  <div class="mt-10">

    <!-- host -->
    <div v-for="(host, index) in hosts" :key="index"
         class="mt-10
                mb-4
                flex
                flex-col">

      <p class="text-l 
                font-bold">Full Name</p>

      <input type="text" 
           placeholder="Enter the hosts full name" 
           v-model="host.fullName"
           :name="`hosts[${index}][fullName]`"
           class="w-full
                  p-2
                  mt-2
                  mb-6
                  border-none
                bg-white">


      <p class="text-l 
                font-bold">Short Bio</p>

      <textarea class="form-control
                        w-full
                        p-2
                        mt-2
                        border-none
                      bg-white"
                rows="5"
                placeholder="Host short bio"
                v-model="host.bio"
                :name="`hosts[${index}][bio]`"></textarea>

      <button @click="removeHost(index)" 
               class="h-10 
                      rounded-md 
                      p-2
                      mt-2
                      transition 
                      duration-300  
                      ease-in-out
                      flex
                      self-end">

          <img class="h-5 
                      w-5" 
              :src="deleteIcon" 
               alt="Delete icon" />
          
          <p class="font-bold 
                    ml-2
                    navigationLink
                    text-grey">Remove host</p>
        </button>
    </div>

    <button @click="addHost"
             class="items-center
                  bg-darkgrey
                  hover:bg-red
                  text-white
                    font-bold
                    text-center
                    rounded-full
                    px-6
                    py-2
                    transition 
                    duration-300 
                    ease-in-out
                    cursor-pointer">Add a host</button>

  </div> 

  <!--  Publish / Cancel -->
  <div class="mt-12 
              flex
              items-center
              justify-end">

    <router-link :to="{ name: 'AdminShowsVlogs' }" 
                  class="font-bold 
                         mr-6
                         navigationLink
                        text-grey">
      Cancel
    </router-link>

     <button @click="saveToFirebase"
             class="items-center
                  bg-darkgrey
                  hover:bg-red
                  text-white
                    font-bold
                    text-center
                    rounded-full
                    px-6
                    py-2
                    transition 
                    duration-300 
                    ease-in-out
                    cursor-pointer">Save Changes</button>

  </div>  

</template>

<script>
import deleteIcon from '@/assets/images/icons/trash-regular.svg'

import BackButton from '@/components/AdminBackButton.vue'
import QuillEditor from '@/components/QuillEditor.vue'
import Loading from '@/components/Loading.vue'
import Modal from '@/components/Modal.vue'

// firebase
import { timestamp } from '@/config/firebase'

import uploadFileToFirebaseStorage from '@/composables/uploadFileToFirebaseStorage'
import { updateDocument } from '@/composables/firebaseFirestore'

// vue
import { computed, ref, onMounted } from 'vue'

// vue-router
import { useRouter, useRoute } from 'vue-router'

// vuex
import { useStore } from 'vuex'

export default {
    name: 'AdminEditPodcast',
    components: {
      BackButton,
      Loading,
      QuillEditor,
      Modal
    },
    setup() {
      onMounted(() => {
        document.getElementsByClassName("adminVlogs").forEach(el => el.classList.add("router-link-active"))
        document.getElementsByClassName("adminPodcasts").forEach(el => el.classList.remove("router-link-active"))
      })

      const store = useStore()
      const router = useRouter()
      const route = useRoute()

      // get current show from the vuex store
      const show = store.getters.content('vlog', route.params.id)

      const logo = ref(null)
      const name = computed({
        get() {
          return store.state.content.temporary.name
        },
        set(payload) {
          store.commit('addShowName', payload)
        }        
      })
      const showDescription = computed(() => store.state.content.temporary.description)
      const episodesURL = computed({
        get() {
          return store.state.content.temporary.episodesURL
        },
        set(payload) {
          store.commit('addShowEpisodesURL', payload)
        }        
      })
      const featuredCheckbox = computed({
        get() {
          return store.state.content.temporary.featured
        },
        set(payload) {
          store.commit('featured', payload)
        }        
      })
      const hosts = computed({
        get() {
          return store.state.content.temporary.hosts
        },
        set(payload) {
          store.commit('addHost', payload)
        }        
      })

      let file = null
      let error = null
      let errorMsg = null


      // clear all values from vuex store temporary show object
      store.commit("resetTemporaryShowState")

      // update vuex store temporary show object with the show data
      store.commit("setTemporaryShowState", show)



      // delete the show
      const displayModal = ref(false)

      const toggleModal = () => displayModal.value = !displayModal.value 
      
      const deleteShow = () => {
        // call vuex action to delete the show on Firebase and in the VueX state
        store.dispatch("deleteContent", show) 

        // hide the modal
        toggleModal()

        // redirect the user to the new article view
        router.push({ 
          name: 'AdminShowsVlogs'
        })
      }


      // logo
      let logo_url = show.logo_url !== "" 
        ? show.logo_url 
        : 'https://firebasestorage.googleapis.com/v0/b/twenty-one-media.appspot.com/o/site-assets%2F21MediaPlaceHolderContentLogo.png?alt=media&token=0c51e4f6-169d-455c-8f82-d1d79bbc210c'

      const generateLogoURLAndFilename = async file => {
        // display the loading spinner
        toggleLoadingSpinner()

        logo_url = await uploadFileToFirebaseStorage(file, 'content/shows/vlogs/')

        // hide the loading spinner
        toggleLoadingSpinner()
      }

      const logoFileChanged = () => {

         // get the first file only
        const file = logo.value.files[0]

        // update vue state
        store.commit('createLogoURL', URL.createObjectURL(file))

        generateLogoURLAndFilename(file)
      }


      // hosts
      const addHost = host => store.commit('addHost', {
        fullName: host.fullName,
        bio: host.bio 
      })

      const removeHost = index => store.commit('removeHost', index)



      // save to the database
      const saveToFirebase = async () => {

        if(name.value && showDescription.value) {

          // display the loading spinner
          toggleLoadingSpinner()

          store.commit('removeInvalidHosts')
          
          const data = {
            id: show.id,
            updatedAt: timestamp.now(),
            name: name.value,
            logo_url: logo_url,
            type: 'vlog',
            description: showDescription.value.htmlContent,
            episodesURL: episodesURL.value,
            featured: featuredCheckbox.value,
            hosts: hosts.value
          } 

          try {
            // updates current document on Firebase
            await updateDocument({ collectionName: 'vlogs', 
                                   data: data, 
                                   documentFirebaseId: data.id 
                                  })

            // update VueX store with new content for current show
            store.dispatch('updateContent', data)
            

            // redirect the user to the new article view
            router.push({ 
              name: 'AdminShowsVlogs'
            })

            // hide the loading spinner
            toggleLoadingSpinner()

          } catch(error) {
            console.log(error)
                            
            // hide the loading spinner
            toggleLoadingSpinner()
          }
        } 
      }
      
      const toggleLoadingSpinner = () => store.commit('toggleLoadingSpinner')
      
      // update vuex store as changes are emitted from the Quilleditor component
      const editorContentChanged = description => store.commit('showDescription', description)
    
      return {
        file,
        error,
        errorMsg,
        deleteIcon,
        show,
        name,
        episodesURL,
        featuredCheckbox,
        logo,
        logo_url,
        logoFileChanged,
        editorContentChanged,
        hosts,
        addHost,
        removeHost,
        saveToFirebase,
        displayModal,
        toggleModal,
        deleteShow
      }
    }
}
</script>