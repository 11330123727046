<template>

  <div class="flex
              w-9/12
              sm:w-4/12
              md:w-4/12
              lg:w-1/4
              mx-auto
              mb-10
              font-semibold">
    <router-link :to="{ name: 'ShowsPodcasts' }">
      <p class="navigationLink
                px-4
                ml-4">Podcasts</p> 
    </router-link>
    |
    <p class="text-red
               px-4">Vlogs</p>
  </div>

  <ShowsWatchHeader />

  <section v-if="featuredContent">

    <DownButton to="featured" />

     <h2 id="featured"
         class="font-regular
                text-3xl
                md:text-3xl">
      Featured Vlog<span class="text-red">.</span>
    </h2>

    <div class="mb-20
                sm:grid 
                sm:grid-cols-1
                sm:gap-4">

      <Featured :featuredContent="featuredContent" />
    </div>

  </section>

  <section v-if="vlogs">

    <DownButton to="vlogs" />

     <h2 id="vlogs"
         class="font-regular
                text-3xl
                mb-10
                md:text-3xl">
      Vlogs<span class="text-red">.</span>
    </h2>

    <div v-if="vlogs.length > 0">

      <div class="grid
                  grid-cols-2
                  gap-8
                  md:grid-cols-3
                  lg:grid-cols-3
                  xl:grid-cols-4
                  relative">

        <div class="px-auto" 
              v-for="(vlog, index) in vlogs" 
              :key="index">

            <router-link :to="{ name: 'ShowDetail',
                      params: {
                          type: vlog.type,
                          id: vlog.id
                      } }">
              <img v-if="vlog.logo_url"
                   :src="vlog.logo_url"
                   class="w-52
                          h-auto
                          max-h-52
                          rounded-xl
                          bg-darkgrey" />
              <div v-else
                   class="w-52
                          h-auto
                          rounded-xl
                          bg-darkgrey"></div>
          
            </router-link>

        </div>

      </div>

    </div>
    <div v-else>
      <p>Shows coming soon.</p>
    </div>

    <UpButton to="top" />

  </section>

</template>

<script>
import ShowsWatchHeader from '@/components/ShowsWatchHeader.vue'
import Featured from '@/components/Featured.vue'
import DownButton from '@/components/DownButton.vue'
import UpButton from '@/components/UpButton.vue'

// vue
import { computed, onMounted, onUnmounted } from 'vue'

// vuex
import { useStore } from 'vuex'

export default {
  name: 'ShowsVlogs',
  components: {
    ShowsWatchHeader,
    Featured,
    DownButton,
    UpButton
  },
   setup() {
     
    // add / remove link styling when mounting and unmouting the view
    onMounted(() => document.getElementsByClassName("shows").forEach(el => el.classList.add("router-link-active")))
    onUnmounted(() => document.getElementsByClassName("shows").forEach(el => el.classList.remove("router-link-active")))

     const store = useStore()
    
     const featuredContent = computed(() => store.getters.featuredContent('vlogs'))

     const vlogs = computed(() => store.getters.vlogs(true))

     return {
       featuredContent,
       vlogs
     }
   }
}
</script>