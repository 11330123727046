<template> 
  <button class="transition 
                 duration-300 
                 ease-in-out 
                 text-center
                 py-2 
                 px-4
                 bg-red 
                 hover:bg-darkgrey 
                 hover:text-red
                 font-semibold
                 text-base
                 tracking-wide
                 rounded-lg">
    {{ buttonText }}
  </button>
</template>

<script>
// vue
import { computed } from 'vue'

export default {
  props: [
    'buttonText',
    'darkMode',
    'paddingX',
    'paddingY'
  ],
  setup(props) {
    // computed
    const colourStyles = computed(() => 'bg-red hover:bg-darkgrey hover:text-red')
    const paddingStyles = computed(() => props.paddingX && props.paddingY ? `py-${props.paddingY} px-${props.paddingX}` : 'py-2 px-4')

    return {
      colourStyles,
      paddingStyles
    }
  }
}
</script>